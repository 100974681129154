document.addEventListener("DOMContentLoaded", function () {
  const ham = document.querySelector(".ham");
  const cross = document.querySelector(".cross");
  const navList = document.querySelector(".nav-list");
  const body = document.body;

  const servicesList = [
    { service: ".services", dropdown: ".dropdown_menu1" },
    { service: ".services1", dropdown: ".dropdown_menu2" },
    { service: ".services2", dropdown: ".dropdown_menu3" },
    { service: ".services3", dropdown: ".dropdown_menu4" },
  ];

  function isMobile() {
    return window.innerWidth <= 1080; // Mobile breakpoint
  }

  function toggleDropdown(dropdown) {
    dropdown.style.display =
      dropdown.style.display === "flex" ? "none" : "flex";
  }

  // Initialize nav toggler
  function setInitialVisibility() {
    if (navList.classList.contains("active")) {
      ham.style.display = "none";
      cross.style.visibility = "visible";
    } else {
      ham.style.display = "block";
      cross.style.visibility = "hidden";
    }
  }

  setInitialVisibility();

  ham.addEventListener("click", function () {
    navList.classList.add("active");
    body.classList.add("no-scroll");
    ham.classList.add("active");
    cross.classList.add("active");
    cross.style.visibility = "visible";
  });

  cross.addEventListener("click", function () {
    navList.classList.remove("active");
    body.classList.remove("no-scroll");
    ham.classList.remove("active");
    cross.classList.remove("active");
    cross.style.visibility = "hidden";
  });

  // Add event listeners for each service and dropdown
  servicesList.forEach(({ service, dropdown }) => {
    const serviceElement = document.querySelector(service);
    const dropdownElement = document.querySelector(dropdown);

    if (serviceElement && dropdownElement) {
      // Desktop: Mouseover and mouseleave events
      serviceElement.addEventListener("mouseover", function () {
        if (!isMobile()) dropdownElement.style.display = "grid";
      });

      serviceElement.addEventListener("mouseleave", function () {
        if (!isMobile()) dropdownElement.style.display = "none";
      });

      // Mobile: Touchstart and click events
      serviceElement.addEventListener("touchstart", function (e) {
        if (isMobile()) {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown(dropdownElement);
        }
      });

      serviceElement.addEventListener("click", function (e) {
        if (isMobile()) {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown(dropdownElement);
        }
      });

      // Keep dropdown open when hovering over it on desktop
      dropdownElement.addEventListener("mouseover", function () {
        if (!isMobile()) dropdownElement.style.display = "grid";
      });

      dropdownElement.addEventListener("mouseleave", function () {
        if (!isMobile()) dropdownElement.style.display = "none";
      });
    }
  });

  // Close all dropdowns when clicking or touching outside
  function closeAllDropdowns(e) {
    servicesList.forEach(({ service, dropdown }) => {
      const dropdownElement = document.querySelector(dropdown);
      const serviceElement = document.querySelector(service);

      if (
        dropdownElement &&
        serviceElement &&
        !dropdownElement.contains(e.target) &&
        !serviceElement.contains(e.target)
      ) {
        dropdownElement.style.display = "none";
      }
    });
  }

  document.addEventListener("click", closeAllDropdowns);
  document.addEventListener("touchstart", closeAllDropdowns);
});

// Variable to store the timeout ID for debounce
// let resizeTimeout1;

// // Add an event listener for the window resize event
// window.addEventListener('resize', () => {
//   // Clear any previous timeout if resize continues happening
//   clearTimeout(resizeTimeout1);

//   // Set a new timeout to refresh the page after 500ms of no resizing
//   resizeTimeout1 = setTimeout(() => {
//     window.location.reload(); // Refresh the page when resize stops for 500ms
//   }, 500);
// });
